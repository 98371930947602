import React, { FC } from "react";
import { Portfolio as PortfolioJSON } from "../Portfolio";
import { ReactComponent as Close } from "../assets/Close.svg";
import "../styles/Portfolio.scss";

export const Portfolio: FC<{
  name: string;
  post: string;
  image: string;
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ name, post, image, open, setIsOpen }) => {
  const portfolio = PortfolioJSON[name];

  if (!open || !portfolio) return <></>;

  return (
    <div id="portfolio">
      <div id="portfolio__header">
        <Close id="portfolio__header__svg" onClick={() => setIsOpen(false)} />
        <img src={image} alt="portfolio" id="portfolio__header__image" />
        <div>
          <p id="portfolio__header__name"> {name}</p>
          <p id="portfolio__header__post">{post}</p>
        </div>
      </div>
      <p id="portfolio__post">{portfolio.post}</p>
      <ul id="portfolio__postData">
        {portfolio.postData.map((item: string) => (
          <li key={item} className="portfolio__postData__item">
            {item}
          </li>
        ))}
      </ul>
      <p id="portfolio__experience">Experience</p>
      {portfolio.experience.map((item: any) =>
        Object.entries(item).map(([key, value]: [key: any, value: any]) => (
          <div key={value}>
            <li key={key} className="portfolio__experience__item">
              {key}
            </li>
            <ul className="portfolio__experience__item__container" key={value}>
              {value.map((text: string) => (
                <li
                  key={text}
                  className="portfolio__experience__item__container__text"
                >
                  {text}
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
      <p id="portfolio__education">{portfolio.education}</p>
    </div>
  );
};
