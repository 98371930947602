import { FC } from "react";
import { Element } from "react-scroll";
import "../styles/Team.scss";
import { TeamBlock } from "./TeamBlock";
import Sergei from "../assets/Sergei.webp";
import Gleb from "../assets/Gleb.webp";
import Viktor from "../assets/Viktor.webp";

export const Team: FC = () => {
  return (
    <section id="team">
      <Element name="team">
        <h3 id="team__title">Team</h3>
        <div id="team__container">
          <TeamBlock name="Sergei Viktorov" post="CEO" image={Sergei} />
          <TeamBlock name="Gleb Shkapskii" post="CTO" image={Gleb} />
          <TeamBlock name="Viktor Shirokov" post="CMO" image={Viktor} />
        </div>
        <div  id="team__image"/>
      </Element>
    </section>
  );
};
