import { FC } from "react";
import "../styles/Main.scss";
import { ReactComponent as Write } from "../assets/Write.svg";
import Deco from "../assets/Deco.png";
import Planet from "../assets/Planet.webp";
import { useScroll } from "../Hooks";

export const Main: FC = () => {
  const scroll = useScroll();

  return (
    <main id="main">
      {/*  Для компонента arrow*/}
      <div style={{ display: "none" }}>
        <p>About</p>
      </div>
      <h2 id="main__title">
        Research
        <br />& Development
      </h2>
      <h5 id="main__description">
        Essential software tools for analytics, visualization, automatization
        and monitoring.
      </h5>
      <button id="main__button" onClick={scroll("contact")}>
        Write to us <Write />
      </button>
      <img alt="deco" src={Deco} id="main__deco" />
      <img alt="planet" src={Planet} id="main__planet" />
    </main>
  );
};
