import { FC } from "react";
import { Element } from "react-scroll";
import "../styles/About.scss";

export const About: FC = () => {
  return (
    <section id="about">
      <Element name="about">
        <h3 id="about__title">About us</h3>
        <p id="about__description">
          Chain Violin DMCC is a data-driven solution provider. We support
          customers' digital transformation by researching their market and
          developing essential software tools for analytics, visualization,
          automatization and monitoring.
        </p>
      </Element>
      <div id="about__image"></div>
    </section>
  );
};
