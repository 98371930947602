import { FC } from "react";
import { Element } from "react-scroll";
import "../styles/Contact.scss";
import { Footer } from "./Footer";

export const Contact: FC = () => {
  return (
    <section id="contact">
      <Element name="contact">
        <h3 id="contact__title">Contact us</h3>
        <div id="contact__wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.887820414659!2d55.14095797643066!3d25.071791036766907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6cae9aa0173d%3A0x764db129ad54ae51!2sDubai%20Arch%20Tower%20-%20Jumeirah%20Lake%20Towers%20-%20Dubai!5e0!3m2!1sen!2sae!4v1685579268735!5m2!1sen!2sae"
            width="600"
            height="450"
            loading="lazy"
            id="contact__map"
          ></iframe>
          <div id="contact__map-block"></div>
          <div id="contact__container">
            <h2>Chain Violin DMCC</h2>
            <p className="contact__container__text">
              <b>DUNS:</b> 557582916
            </p>
            <p className="contact__container__text">
              <b>Licence number:</b> DMCC-845770
            </p>
            <p className="contact__container__text">
              <b>Mail:</b> info@chainviolin.com
            </p>
            <p className="contact__container__text">
              <b>Phone:</b> +971 56 136 86 47
            </p>
            <p className="contact__container__text">
              <b>Address:</b> Unit No: 201 Dubai Arch Tower,
              <br />Plot No: JLT-PH1-G1A Jumeirah Lakes Towers, 
              <br />P.O. Box: 337338
              <br /> Dubai <br /> United Arab Emirates
            </p>
          </div>
        </div>
        <Footer />
      </Element>
    </section>
  );
};
