import { FC } from "react";

export const Footer: FC = () => {
  return (
    <footer>
      <div style={{ display: "none" }}>
        <p>Contact us</p>
      </div>
      <p>© CHAIN VIOLIN DMCC 2022</p>
    </footer>
  );
};
