import { FC, useState, ReactNode } from "react";
import { ReactComponent as More } from "../assets/More.svg";
import { useScroll } from "../Hooks";

export const Solution: FC<{
  title: string;
  data: string[];
  tools: string[];
  children: ReactNode;
  right?: boolean;
}> = ({ title, data, tools, children, right }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const scroll = useScroll();

  return (
    <>
      <div className={`solution ${right ? "solution-right" : ""}`}>
        <div
          className="solution__container"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <p className="solution__title">{title}</p>
          <More className={!isOpen ? "solution__container__svg-active" : ""} />
        </div>
        <div className={!isOpen ? "solution-hidden" : ""}>
          <p className="solution__tag">DATA</p>
          {data.map((d) => (
            <p className="solution__data" key={d}>
              {d}
            </p>
          ))}
          <p className="solution__tag">TOOLS</p>
          {tools.map((d) => (
            <p className="solution__data" key={d}>
              {d}
            </p>
          ))}
          <button className="solution__button" onClick={scroll("contact")}>
            Request a solution
          </button>
          {children}
        </div>
      </div>
    </>
  );
};
