import { FC, useState } from "react";
// import logo from "../assets/Logo.png";
import "../styles/Header.scss";
import { ReactComponent as Burger } from "../assets/Burger.svg";
import { ReactComponent as Close } from "../assets/Close.svg";
import { ReactComponent as Logo } from "../assets/ChainViolinInverse.svg";
import stars from "../assets/Stars.webp";
import {useScroll} from "../Hooks";

export const Header: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const scroll = useScroll(setIsOpen);

  return (
    <header
      id={isOpen ? "header-active" : ""}
      style={
        isOpen ? { backgroundImage: `url(${stars})`, position: "fixed" } : {}
      }
    >
      {/* <img id="header__logo" alt="logo" src={logo} /> */}
      {/* <h1 id="header__name">Chain Violin</h1> */}
      <Logo id="header__logo_svg" width={200} />
      {isOpen ? (
        <Close id="header__close" onClick={() => setIsOpen(false)} />
      ) : (
        <Burger id="header__burger" onClick={() => setIsOpen(true)} />
      )}
      <menu className="header__menu" id={isOpen ? "header__menu-active" : ""}>
        <li className="header__menu__link" onClick={scroll("about")}>
          About us
        </li>
        <li className="header__menu__link" onClick={scroll("technology")}>
          Technology stack
        </li>
        <li className="header__menu__link" onClick={scroll("industry")}>
          Solutions
        </li>
        <li className="header__menu__link" onClick={scroll("team")}>
          Team
        </li>
        <li className="header__menu__link" onClick={scroll("contact")}>
          Contact us
        </li>
      </menu>
    </header>
  );
};
