import React, { useEffect, useState } from "react";
import { scroller } from "react-scroll";

export function useMobile(): [boolean, boolean] {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;
  const isTablet = !isMobile && width <= 1200;

  return [isMobile, isTablet];
}

export const useTouch = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
  page: number,
  length: number
): [
  React.Dispatch<React.SetStateAction<number>>,
  React.Dispatch<React.SetStateAction<number>>,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [startX, setStartX] = useState<number>(0);
  const [endX, setEndX] = useState<number>(0);
  const [wait, setWait] = useState<boolean>(false);

  useEffect(() => {
    if (wait) return;
    const isNext = endX - startX > 30;
    const isPrev = endX - startX < 30 && endX - startX !== 0;
    if (isNext && page < length - 1) setPage(page + 1);
    if (isNext && page === length - 1) setPage(0);
    if (isPrev && page > 0) setPage(page - 1);
    if (isPrev && page === 0) setPage(length - 1);
  }, [startX, endX, wait, length, setPage]);

  return [setStartX, setEndX, setWait];
};

export const useScroll = (
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const customOffsets: { [key: string]: number } = {
    about: -350,
    technology: -300,
    industry: -150,
    mice: -200,
    team: -200,
    contact: 300,
  };
  const [isMobile, isTablet] = useMobile();

  function scroll(section: string): () => void {
    return () => {
      if (setIsOpen) setIsOpen(false);
      scroller.scrollTo(section, {
        duration: 1000,
        delay: 0,
        smooth: "easeInOutQuint",
        offset: isMobile ? 0 : isTablet ? -400 : customOffsets[section] || -150,
      });
    };
  }

  return scroll;
};
