import React, { FC, useEffect, useState } from "react";
import { Portfolio } from "./Portfolio";

export const TeamBlock: FC<{
  name: string;
  post: string;
  image: string;
}> = ({ name, post, image }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) document.querySelector("html")!.style.overflow = "auto";
    else document.querySelector("html")!.style.overflow = "hidden";
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <div id="portfolio-background" onClick={() => setIsOpen(false)}></div>
      ) : (
        <></>
      )}
      <Portfolio
        name={name}
        post={post}
        image={image}
        open={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="team__container__block">
        <img alt="team" className="team__container__block__image" src={image} />
        <div>
          <p className="team__container__block__name">{name}</p>
          <p className="team__container__block__post">{post}</p>
          <button
            className="team__container__block__button"
            onClick={() => setIsOpen(true)}
          >
            BIO
          </button>
        </div>
      </div>
    </>
  );
};
