import React, { FC, useEffect } from "react";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { Arrow } from "./components/Arrow";
import { About } from "./components/About";
import { Technology } from "./components/Technology";
import { Solutions } from "./components/Solutions";
import { Team } from "./components/Team";
import { Contact } from "./components/Contact";
import portfolio from "./assets/Portfolio.webp";
import stars from "./assets/Stars.webp";

export const App: FC = () => {
  useEffect(() => {
    [stars, portfolio].forEach(
      (image) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function () {
            resolve(img);
          };
          img.onerror = img.onabort = function () {
            reject(image);
          };
          img.src = image;
        })
    );
  }, []);

  return (
    <>
      <Header />
      <Main />
      <Arrow />
      <About />
      <Technology />
      <Solutions />
      <Team />
      <Contact />
    </>
  );
};

export default App;
