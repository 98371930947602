import { FC, useEffect, useState } from "react";
import { ReactComponent as ArrowSVG } from "../assets/Arrow.svg";
import { useScroll } from "../Hooks";

export const Arrow: FC = () => {
  const [title, setTitle] = useState<string>("About us");
  const [lastVisible, setLastVisible] = useState<string>("");
  const scroll = useScroll();

  useEffect(() => {
    const DOMElements = [
      document.querySelector("main"),
      document.getElementById("about"),
      document.getElementById("technology"),
      document.getElementById("solutions"),
      document.getElementById("DLT_Solution"),
      document.getElementById("E-commerce_Solution"),
      document.getElementById("MICE_Solution"),
      document.getElementById("team"),
      document.getElementById("contact"),
      document.querySelector("footer"),
    ];

    function visible() {
      const windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      };

      DOMElements.forEach((target) => {
        const targetPosition = {
          top: window.pageYOffset + target!.getBoundingClientRect().top,
          left: window.pageXOffset + target!.getBoundingClientRect().left,
          right: window.pageXOffset + target!.getBoundingClientRect().right,
          bottom: window.pageYOffset + target!.getBoundingClientRect().bottom,
        };

        if (
          targetPosition.bottom > windowPosition.top &&
          targetPosition.top < windowPosition.bottom &&
          targetPosition.right > windowPosition.left &&
          targetPosition.left < windowPosition.right
        ) {
          const index = DOMElements.indexOf(target);
          const lastVisibleIndex = DOMElements.indexOf(
            DOMElements.find((e) => e!.id === lastVisible)!
          );
          const isMinus = index < lastVisibleIndex;
          const element =
            DOMElements[isMinus ? index - 1 : index + 1] ??
            DOMElements[isMinus ? 0 : DOMElements.length];
          if (element) {
            setTitle(element!.children[0].children[0].textContent!);
          } else setTitle("");
          setLastVisible(target!.id);
        }
      });
    }

    window.addEventListener("scroll", visible);
    return () => window.removeEventListener("scroll", visible);
  }, [title, lastVisible]);

  return (
    <div id="arrow" style={!title ? { opacity: 0, visibility: "hidden" } : {}}>
      <div onClick={scroll(title.split(" ")[0].toLowerCase())}>
        {title || "Contact us"}
        <ArrowSVG />
      </div>
    </div>
  );
};
