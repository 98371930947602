import { FC } from "react";
import { Element } from "react-scroll";
import "../styles/Solutions.scss";
import { Solution } from "./Solution";
import industryRectangle from "../assets/IndustryRectangle.png";
import NFT from "../assets/NFT.png";
import Ecommerce from "../assets/E-commerce.webp";
import Mice from "../assets/Mice.webp";

export const Solutions: FC = () => {
  return (
    <section id="solutions">
      <Element name="industry">
        <h3 id="solutions__title">Industry solutions</h3>
        <Solution
          title="TV & Broadcast Virtual Production"
          data={[
            "VP pipeline: LED wall, Chromakey, XR, AR setup",
            "Merged classic and VP pipeline",
            "Docs and educational materials",
          ]}
          tools={[
            "Unreal Engine plugins",
            "Broadcast hardware integration",
            "Camera tracking and calibration software",
            "Real-time Broadcast Compositing System",
          ]}
        >
          <img
            alt="industryRectangle"
            id="industryRectangle"
            src={industryRectangle}
          />
          <iframe
            id="industry"
            src="https://www.youtube.com/embed/kzW5v3vPhws"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Solution>
      </Element>
      <Element name="dlt" id="DLT_Solution">
        <div style={{ display: "none" }}>
          <p>DLT Solution</p>
        </div>
        <Solution
          right={true}
          title="NFT market analytics"
          data={[
            "Ethereum blockchain transaction history",
            "First-market mint activity",
            "Second-market trade activity",
            "Whales portfolio activity",
            "Traders and creator’s social activity",
          ]}
          tools={[
            "Blockchain data collector and visualizer",
            "Trading and minting predictive models",
            "Neural predictive network",
            "Market activity reports sharing via API",
          ]}
        >
          <img alt="nft" id="NFT" src={NFT} />
        </Solution>
      </Element>
      <Element name="e-commerce" id="E-commerce_Solution">
        <div style={{ display: "none" }}>
          <p>E-commerce Solution</p>
        </div>
        <Solution
          title="Mobile E-commerce Application Builder"
          data={[
            "User's behavior and preferences",
            "Forecasting model for a product needs",
            "Market anomaly detections",
          ]}
          tools={[
            "iOS / Android application online configurator ",
            "App Store / Google Play application publishing ",
            "Mobile application dynamic changes ",
            "Users activity analytics ",
            "Events push notifications ",
          ]}
        >
          <img alt="E-commerce" id="E-commerce" src={Ecommerce} />
        </Solution>
      </Element>
      <Element name="mice" id="MICE_Solution">
        <div style={{ display: "none" }}>
          <p>MICE Solution</p>
        </div>
        <Solution
          title="WATER TRANSPORT events"
          right={true}
          data={[
            "Fleet management models and structures",
            "VIP customer’s preferences feedback",
            "Water routes profit analysis",
          ]}
          tools={[
            "Food delivery orders on the boat",
            "Fleet management CRM system",
            "Real-time fleet booking system",
          ]}
        >
          <img alt="Mice" id="Mice" src={Mice} />
        </Solution>
      </Element>
    </section>
  );
};
