import { FC, useEffect, useState } from "react";
import "../styles/Technology.scss";
import { Element } from "react-scroll";
import All from "../assets/All.png";
import All1 from "../assets/All1.png";
import All2 from "../assets/All2.png";
import All3 from "../assets/All3.png";
import All4 from "../assets/All4.png";
import AllT1 from "../assets/AllT1.svg";
import AllT2 from "../assets/AllT2.svg";
import AllT3 from "../assets/AllT3.svg";
import Analytics1 from "../assets/Analytics 1.png";
import Analytics2 from "../assets/Analytics 2.png";
import Analytics3 from "../assets/Analytics 3.png";
import AnalyticsT1 from "../assets/AnalyticsT1.svg";
import AnalyticsT2 from "../assets/AnalyticsT2.svg";
import Analytics from "../assets/Analytics.png";
import Visualization from "../assets/Visualization.png";
import Visualization1 from "../assets/Visualisations1.png";
import Visualization2 from "../assets/Visualisations2.png";
import VisualizationT1 from "../assets/VisualisationT1.svg";
import VisualizationT2 from "../assets/VisualisationT2.svg";
import Automatization from "../assets/Automatization.png";
import Automatization1 from "../assets/Automatisation1.png";
import Automatization2 from "../assets/Automatisation2.png";
import AutomatisationT1 from "../assets/AutomatisationT1.svg";
import AutomatisationT2 from "../assets/AutomatisationT2.svg";
import Monitoring from "../assets/Monitoring.png";
import Monitoring1 from "../assets/Monitoring1.png";
import Monitoring2 from "../assets/Monitoring2.png";
import MonitoringT1 from "../assets/MonitoringT1.svg";
import { useMobile, useTouch } from "../Hooks";

export const Technology: FC = () => {
  const [category, setCategory] = useState("All");
  const [page, setPage] = useState<number>(0);

  const [isMobile, isTablet] = useMobile();

  useEffect(() => {
    setPage(0);
  }, [category]);

  const categories = [
    "All",
    "Analytics",
    "Visualization",
    "Automatization",
    "Monitoring",
  ];

  const mobileImages: { [key: string]: string[] } = {
    All: [All1, All2, All3, All4],
    Analytics: [Analytics1, Analytics2, Analytics3],
    Visualization: [Visualization1, Visualization2],
    Automatization: [Automatization1, Automatization2],
    Monitoring: [Monitoring1, Monitoring2],
  };

  const tabletImages: { [key: string]: string[] } = {
    All: [AllT1, AllT2, AllT3],
    Analytics: [AnalyticsT1, AnalyticsT2],
    Visualization: [VisualizationT1, VisualizationT2],
    Automatization: [AutomatisationT1, AutomatisationT2],
    Monitoring: [MonitoringT1],
  };

  const images: { [key: string]: string } = {
    All: All,
    Analytics: Analytics,
    Visualization: Visualization,
    Automatization: Automatization,
    Monitoring: Monitoring,
  };

  const MobImage = mobileImages[category][page];
  const TabImage = tabletImages[category][page];
  const [setStartX, setEndX, setWait] = useTouch(
    setPage,
    page,
    isTablet ? tabletImages[category].length : mobileImages[category].length
  );

  const Image: string = images[category];

  return (
    <section id="technology">
      <Element name="technology">
        <h3 id="technology__title">Technology stack</h3>
        <ul id="technology__list">
          {categories.map((categoryName) => (
            <li
              key={categoryName}
              className="technology__list__block"
              onClick={() => setCategory(categoryName)}
              id={
                categoryName === category
                  ? "technology__list__block-active"
                  : ""
              }
            >
              {categoryName}
            </li>
          ))}
        </ul>
        <div
          style={
            isMobile
              ? { backgroundImage: `url(${MobImage})` }
              : isTablet
              ? { backgroundImage: `url(${TabImage})` }
              : { backgroundImage: `url(${Image})` }
          }
          onTouchStart={({ changedTouches }) => {
            setStartX(changedTouches[0].clientX);
            setWait(true);
          }}
          onTouchEnd={({ changedTouches }) => {
            setEndX(changedTouches[0]!.clientX);
            setWait(false);
          }}
          id="technology__stack-image"
        />
        <div id="technology__container">
          {new Array(
            isTablet
              ? tabletImages[category].length
              : mobileImages[category].length
          )
            .fill(null)
            .map((_, i) => {
              return (
                <div
                  key={i}
                  id={i === page ? "technology__container__round-active" : ""}
                  className="technology__container__round"
                ></div>
              );
            })}
        </div>
        <div id="technology__image"></div>
      </Element>
    </section>
  );
};
