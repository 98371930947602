export const Portfolio: any = {
  "Sergei Viktorov": {
    post: "Business analyst & Chief Digital Transformation Officer (CDO)",
    postData: [
      "a. Industry 4.0 digitalization",
      "b. Product lifecycle management",
      "c. Digital Twins",
      "d. Educational VR training apps",
      "e. Automation of business processes",
    ],
    experience: [
      {
        "a. CORE": [
          "i. Strategy planning, business analysis, connections networking, creating development pipelines",
        ],
      },
      {
        "b. BUSINESS ANALYSIS": [
          "i. Product requirements lifecycle management by IIBA Business Analysis Body of Knowledge (BABOK)",
          "ii. Business processes modelling by BPMN notation",
          "iii. SWOT analysis",
        ],
      },
      {
        "c. PRODUCT MANAGEMENT": [
          "i. MVP prototyping",
          "ii. Agile development (Scrum, Kanban)",
          "iii. Monitoring the achievement of key performance indicators (KPI) by PMI Project Management Body of Knowledge (PMBOK)",
          "iv. Launching MVP hypotheses in the target markets",
        ],
      },
      {
        "d. PLM SOFTWARE CONSULTING": [
          "i. Dassault Systems 3DEXPERIENCE",
          "ii. Siemens Teamcenter",
          "iii. SAP PLM",
          "iv. PTC Windchill",
        ],
      },
    ],
    education: "Degree in IT, economics and management",
  },
  "Gleb Shkapskii": {
    post: "Software engineer (CTO) & business process designer",
    postData: [
      "a. Mobile applications development for e-commerce",
      "b. VR application development",
      "c. Virtual production appliance development for cinematography and broadcasting",
      "d. Deep blockchain analytics",
    ],
    experience: [
      {
        "a. Technical process design and development, core software engineer":
          [],
      },
      {
        "b. Designing and developing high-load IT solutions with an emphasis on reliability and fault tolerance":
          [],
      },
      {
        "c. Analysis of existing business processes, building effective pipelines, automation of processes":
          [],
      },
      {
        "d. In-depth technical analysis in the above areas":
          [],
      },
      {
        "e. Great experience in software tools development for blockchain": [],
      },
      {
        "f. Big data analysis and visualization":
          [],
      },
      {
        "g. Building a company based on preliminary in-depth analytics, financial and resource planning":
          [],
      },
    ],
    education: "Degree in IT, economics and management",
  },
  "Viktor Shirokov": {
    post: "Software product architect",
    postData: [
      "a. Water transportation. Building an automated system for booking and accounting of the fleet",
      "b. Cinematography and broadcasting. Building pipelines of virtual production interaction with classic production and technical production",
      "c. Cinematography and broadcasting. Deep continuous exploration of the virtual production market, data consolidation, generation of reports and presentations for further strategic decision-making at the board of shareholders",
      "d. Blockchain. Exploration and automated data collection for building blockchain analytics",
    ],
    experience: [
      {
        "a. Marketing strategy and research, design of advertising companies, growth driver":
          [],
      },
      {
        "b. In depth research for the ways to solve complex technical issues":
          [],
      },
      {
        "c. Creating connections with technology partners and developing pipelines for further interaction":
          [],
      },
      {
        "d. Preparation of documentations, reports and presentations":
          [],
      },
      {
        "e. Business management based on market research and in-depth analytics":
          [],
      },
      {
        "f. NFT Market Research, blockchain transaction analysis, preparation financial analytics and visualizations":
          [],
      },
      {
        "g. Preparing marketing strategy and estimate the cost of an advertising campaign":
          [],
      },
    ],
  },
};
